import React from 'react'  
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import List from '../components/list'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import GigCard from '../components/card'
import {CardColumns} from 'reactstrap'
import Helmet from 'react-helmet'


const VenueTemplate = ({ data }) => {
  const venue = data.strapiVenues;
  const gigs = data.allStrapiGigs.edges;

  return (
  <Layout>
    <Helmet
            title={`${venue.name} | Laz's Bootlegs`}
            meta={[
              { property: 'og:description', content: `Gigs at ${venue.name} in Larry's collection of bootlegs` },
              { name: 'keywords', content: `music, melbourne, ${venue.name}` },
              { property: 'og:title', content: `${venue.name} | Laz's Bootlegs`},
            ]}
          >
  </Helmet>
    <h2>Gigs at {venue.name}</h2>
    <CardColumns>
    {gigs.map((item , i) => (
            <GigCard key={i} poster={item.node.poster} title={item.node.title} date={item.node.date} slug={item.node.slug} description={item.node.description} venue={venue} />
          ))}
    </CardColumns>
    <p><a href={venue.infolink}>Find out more about {venue.name} at their website</a></p>
  </Layout>
  )
}

export default VenueTemplate

export const query = graphql`  
query VenueTemplate($slug: String!) {
  strapiVenues(slug: {eq: $slug}) {
    name
    address
    gigs {
      date(formatString: "DD MMMM YYYY")
      title
      slug
    }
    infolink
    slug
  }

  allStrapiGigs(
    filter:
      { venue:
          { slug:
            { eq: $slug }
          }
        },
      sort: {fields: [date], order: DESC}
    )
    {
    edges {
      node {
        slug
        poster {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        date(formatString: "DD MMMM YYYY")
        id
        description
        title
        slug
      }
    }
  }
}
`
